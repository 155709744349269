<div class="container-fluid bodydiv">
  <div class="row">
    <div class="col">
      <p>
<!--        <a href="http://www.mealsonwheelsandmore.org/" target="_blank">-->
<!--          <img src="/assets/images/MOWAM-logo.jpg" alt="Meals on Wheels and More" width="142" height="59"/>-->
<!--        </a>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <a href="https://www.familyeldercare.org/" target="_blank">-->
<!--          <img src="/assets/images/familyEldercare.png" alt="Family Eldercare" width="116" height="52"/>-->
<!--        </a>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <br/>-->
        <a href="https://gazellefoundation.org/" target="_blank">
          <img src="/assets/images/gf_logo_2015.png" alt="Gazelle Foundation" width="111" height="100"/>
        </a>
      </p>
    </div>
  </div>
</div>
