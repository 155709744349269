<div class="container-fluid bodydiv">
  <div class="row">
    <div class="col-md-2 order-md-12"><img src="/assets/images/headshot2021.jpg" class="logo"  alt="Amy Farley"></div>
    <div class="col-md-10 order-md-1">
      <h4><i>Driven by value creation</i></h4>
      <br/>
      <br/>
      <p>
        <span style='font-size:20px;'><b>Geospatial Technologist | Senior Software Engineer | AWS Cloud Solutions Architect in Austin, Texas</b></span><br/>


      <br/>
        Click <a href="https://www.linkedin.com/in/amylfarley/" target="_blank">here</a> for professional profile on
        LinkedIn.

      <hr/>
<br/>
      <i>
        <span style='font-size:20px;'>📢 </span><a href="http://www.pagisconference.org/Pages/Schedule.aspx" target="_blank">County Commissioners Association of Pennsylvania Virtual PA GIS Conference (May 12,  2021)</a> </i>
      <br/>
      <span style='font-size:20px;'>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <a
        href="https://vimeo.com/548842949/69188015f7"
        target="blank"> Open-source, serverless web mapping:  A case study for the Agriculture Industry      </a>


      <br/>
      <br/>
        <i>
          <span style='font-size:20px;'>🎓 </span>Pennsylvania State University <a href="https://gis.e-education.psu.edu/mgis" target="_blank">Master of Geographic Information Systems
          </a> (May 2021)</i>
      <br/><br/>
      <blockquote>
        <u>Capstone</u><br/>
        <a routerLink="/academic">Open-source, serverless web mapping:&nbsp;A case study for the Agriculture Industry</a><br/><br/>
             <a href="https://github.com/atxFarley/aws_serverless" target="_blank">GitHub Repo</a>
             <br/>
             <a href="https://capstone.amyfarley.com" target="_blank">https://capstone.amyfarley.com</a>
      </blockquote>
<br/>


        <a
          href="https://news.psu.edu/story/653308/2021/03/31/academics/2021-graduate-exhibition-award-winners-announced"
          target="blank">
          <i>
            <span style='font-size:20px;'>&#10024; </span>First Place Winner – 36th annual Graduate Exhibition  - Research/Engineering (March 2021)
          </i>
        </a>
        <br/><br/>

      Click <a routerLink="/academic">here</a> for all academic work.
      &nbsp;<br/>

      &nbsp;<br/>
    </div>

  </div>
</div>






















