import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AcademicComponent } from "./academic/academic.component";
import { PhilanthropyComponent } from "./philanthropy/philanthropy.component";

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "main"},
  {path: "main", component: MainComponent},
  {path: "academic", component: AcademicComponent},
  {path: "philanthropy", component: PhilanthropyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
