<nav class="navbar navbar-expand-md navbar-dark fixed-top ">
  <div class="container-fluid">
      <a class="navbar-brand logo" href="/">Amy Farley</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-expand-md navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav d-flex align-items-end">
          <li class="nav-item"><a href="https://www.linkedin.com/in/amylfarley/" target="_blank" class="navbarItemLink">
            <i class="fab fa-linkedin-in"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item">
            <a class="navbarItemLink" routerLink="/academic"> <i class="fas fa-briefcase"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item"><a href="https://github.com/atxFarley?tab=repositories" target="_blank"
                                  class="navbarItemLink">
            <i class="fab fa-github"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item"><a href="mailto:afarley@austin.rr.com" class="navbarItemLink"><i
            class="far fa-envelope"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item"><a href="/philanthropy" class="navbarItemLink"><i
            class="fas fa-hands-helping"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item"><a
            href="https://www.gilbertsgazelles.com/gazelletalk/2018/11/14/on-the-podium-amy-farley"
            target="_blank" class="navbarItemLink">
            <i class="fas fa-running"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
          <li class="nav-item"><a href="http://www.austinridgeriders.com/programs/ride-like-a-girl/" target="_blank"
                                  class="navbarItemLink"><i class="fas fa-biking"></i>&nbsp;&nbsp;&nbsp;</a>
          </li>
        </ul>
      </div>
    </div>
</nav>













