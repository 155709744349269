<div class="container-fluid bodydiv">
  <div class="row">
    <div class="col">
      <i>
        <span style='font-size:20px;'>🎓 </span>Pennsylvania State University <a href="https://gis.e-education.psu.edu/mgis" target="_blank">Master of Geographic Information Systems
      </a> (May 2021)</i>
      <br/>
      <br/>
      <span style='font-size:25px;'>📰&nbsp;</span>
      <a
        href="https://news.psu.edu/story/657054/2021/04/29/academics/five-earth-and-mineral-sciences-students-awarded-graduate-research?utm_source=newswire&utm_medium=email&utm_term=657786_HTML&utm_content=05-05-2021-15-41&utm_campaign=EMS%20headlines%20issue"
        target="_blank">
        <i>Penn State News</i>&nbsp;&nbsp;"Five Earth and Mineral Sciences students awarded at Graduate Research
        Exhibition" (April 29, 2021)
      </a>
      <br/>
      <br/>
      <span style='font-size:20px;'>&#10024;&nbsp;</span>
      <a
        href="https://news.psu.edu/story/653308/2021/03/31/academics/2021-graduate-exhibition-award-winners-announced"
        target="blank">
        <i>First Place Winner – 36th annual Graduate Exhibition - Research/Engineering (March 2021)
        </i>
      </a>
      <br/>
      <br/>
      <span style='font-size:11px;'>&#11088;&nbsp;</span>
      <a
        href="https://www.facebook.com/notes/penn-state-online-geospatial-education/student-profile-amy-farley/1708021645921089/"
        target="blank">
        <i>Penn State Online Geospatial Education Student Profile - Amy Farley (February 2018)
        </i>
      </a>
      <br/>
      <br/>
<!--      <a href="http://personal.psu.edu/alf33/" target="_blank">PSU Academic ePortfolio</a>-->
      <br/>
      <br/>
      <table align="center" width="90%" cellpadding="3">
        <tr class="headerrow">
          <th colspan="4">Capstone<br/>Open-source, serverless web mapping:<br/>A case study for the
            Agriculture Industry
          </th>
        </tr>
        <tr>
          <td colspan="2">GEOG 596B: Capstone Project Dissemination</td>
          <td colspan="2">
            <a href="https://www.youtube.com/watch?v=9qbJwcxSQHk#t=21m15s" target="_blank">Capstone Presentation
              (21:15)</a>
            <br/>
            <a href="https://github.com/atxFarley/aws_serverless" target="_blank">GitHub Repo</a>
            <br/>
            <a href="https://capstone.amyfarley.com" target="_blank">Sample web application hosted on
              AWS</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/capstone/596b/Farley_596B_Spring2021.pdf"
               target="_blank">Capstone Presentation PowerPoint slides</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/capstone/596b/Farley_596B_Final.docx"
               target="_blank">Capstone Document</a>
            <br/>
            <br/>
            <a href="https://youtu.be/8BAmIfg0JOc" target="_blank">5 minute Graduate Exhibition Presentation</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/alf33_1727_presentation_0075084726.pdf"
               target="_blank">Graduate Exhibition Presentation Slides</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">GEOG 596A: Capstone Proposal and Review</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/capstone/596a/Farley_596A_Proposal.docx"
               target="_blank">Capstone Proposal Document</a>
            <br/>
            <a href="https://www.youtube.com/watch?v=pf77mvNVYtw#t=48m30s&feature=youtu.be" target="_blank">Capstone
              Proposal
              presentation (48:30)</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/capstone/596a/Farley_596A_Proposal.pptx"
               target="_blank">Capstone Proposal PowerPoint
              presentation</a>
          </td>
        </tr>
        <tr class="headerrow">
          <th colspan="4">GEOG 868<br/>Spatial Database Management</th>
        </tr>
        <tr>
          <td colspan="2">Final Project</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/final/afarley_final.docx" target="_blank">Database
              Design for MGIS Capstone</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Project 8: Mapping Charlottesville, Part II</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson8/afarley_Lesson8.docx"
               target="_blank">Workflow Document</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Project 7: Mapping Charlottesville</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson7/afarley_Lesson7.docx"
               target="_blank">Deliverable Document</a>
          </td>
        </tr>

        <tr>
          <td colspan="2">Project 4: Jen and Barry's Site Selection in PostGIS</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson4/afarley_Lesson4.docx"
               target="_blank">Deliverable Document</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Project 3: Mapping the Class Roster</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson3/afarley_Lesson3.docx"
               target="_blank">Workflow Document</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Project 2: Designing a Relational Database</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson2/UFOSightings_ER.png"
               target="_blank">Relational Database Design ER
              Diagram</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson2/afarley_Lesson2.docx"
               target="_blank">ER Diagram Narrative</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Project 1: Writing SQL SELECT Queries</td>
          <td colspan="2">

            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog868/lesson1/baseball_stats.accdb"
               target="_blank">Baseball Stats MS Access
              Database</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 892<br/>Geospatial Applications of Unmanned Aerial Systems (UAS)</th>
        </tr>
        <tr>
          <td colspan="2">Final Project: Design Requirements for a UAS-based Operations</td>
          <td colspan="2">

            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/afarley_final_project.docx"
               target="_blank">Final Project Design Document</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/afarley_final.pptx" target="_blank">Supplemental
              Power Point presentation</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Digital Image Classification</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/afarley_lulc.docx" target="_blank">Land
              Cover/Land Use Classification</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Part 107 Waiver</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/part107waiver_farley.docx" target="_blank">Part
              107 Waiver Application</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">CONOP and Risk Assessment</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/afarley_conop_risk.docx" target="_blank">Concept
              of Operations and Risk
              Assessment</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Pix4D Exercise - UAS Data Processing and Volume Calculations</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/pix4d/exercise1/Quality Report.pdf"
               target="_blank">Pix4D Data Processing
              Quality Report</a>
            <br/>
            <a
              href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/pix4d/exercise1/WiregrassVolumeScreenshot2.jpg"
              target="_blank">Volume
              Screenshot</a>
            <br/>
            <a
              href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/pix4d/exercise1/Exercise_1_Wiregrass_Volume.xlsx"
              target="_blank">Spreadsheet</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Pix4D Exercise - UAS Data Processing and Planimetric Map Making</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/pix4d/exercise2/Quality Report.pdf"
               target="_blank">Pix4D Data Processing
              Quality Report</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog892/pix4d/exercise2/Farley_orthoAccuracy.xlsx"
               target="_blank">Ortho Accuracy
              Spreadsheet</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 865<br/>Cloud and Server GIS
            <br/>
            <a href="https://github.com/atxFarley/GEOG865" target="_blank" class="headerrowlink">GitHub Repo for GEOG
              865</a>
          </th>
        </tr>
        <tr>
          <td colspan="2">Final Project - Open-Source GIS in the Cloud</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/termProject/865_termProject.docx"
               target="_blank">Final Project Document</a>
            <br/>

            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/termProject/termProject.mp4"
               target="_blank">Final Project Video
              Presentation</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/termProject/termProject.pptx"
               target="_blank">Supplemental Power Point
              presentation</a>
            <br/>

            <a href="https://github.com/atxFarley/GEOG865/tree/master/termProject" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 7 - Web maps and data as services using ArcGIS Online</td>
          <td colspan="2">
            <a href="https://pennstate.maps.arcgis.com/apps/View/index.html?appid=fad75d9022674582beddbcee28a77d2e"
               target="_blank">Assignment 7</a>
            <br/>
            <a href="https://playful-xenon.glitch.me" target="_blank">Link to Glitch.com project</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 6 - Doing your own mapping with CARTO Services</td>
          <td colspan="2">
            <a href="https://afarley.carto.com/builder/fcefb9c9-985f-420c-abb1-5679e2ca4188/embed" target="_blank">CSA
              map aggregated to Portland neighborhood boundaries</a>
            <br/>
            <a href="https://afarley.carto.com/builder/39484aaf-e61a-4c01-bf1b-f091a29ac969/embed" target="_blank">Time-series
              map of Philadelphia shootings</a>
            <br/>
            <a href="https://afarley.carto.com/builder/85a9fe36-9d26-4674-a180-d1b4771a8e75/embed" target="_blank">Mapping
              my own dataset</a>
            <br/>
            <a href="https://afarley.carto.com/builder/577562ab-bff9-4ecb-bfa7-e28e05e8104c/embed" target="_blank">Mapping
              my own dataset and experimentation</a>
            <br/>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 5 - Put your own data into Mapbox</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/Lesson5/Lesson5.html" target="_blank">Assignment
              5</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 4 - Putting it all together with ArcGIS Server</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/Lesson4/Lesson4.mp4"
               target="_blank">Video</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog865/Lesson4/Lesson4.pptx" target="_blank">Supplemental
              PowerPoint</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 883<br/>Remote Sensing Image Analysis and Applications</th>
        </tr>
        <tr>
          <td colspan="2">Term Project Story Map<br/>
            Automated Feature Extraction using eCognition - <br/>Land Use and Land Cover classification of Landsat 5 and
            Landsat 8 imagery<br/>
            Change Detection using ArcGIS Pro
          </td>
          <td colspan="2">
            <a href="https://pennstate.maps.arcgis.com/apps/MapSeries/index.html?appid=c4ab95c8aaec4a139e8bf667ade8ca0f"
               target="_blank">Story Map</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 863<br/>Web Application Development for Geospatial Professionals</th>
        </tr>
        <tr>
          <td colspan="2">Final Project - Mapping IRS Opportunity Zones & Charting County Poverty Levels</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/863final/index.html" target="_blank">Final
              Project</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/863final/securingContenttscproj.mp4"
               target="_blank">Advanced Topic Presentation -
              Secured Content/Authentication</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/863final/security.pptx" target="_blank">Supplemental
              Power Point presentation</a>
            <br/>
            <a href="http://personal.psu.edu/alf33/863final/secureApp/" target="_blank">Link to
              Secured Demo App</a>
            <br/>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 8 - Geoprocessing</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson8/index.html" target="_blank">Assignment
              8</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 7 - GUI Development</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson7/index.html" target="_blank">Assignment
              7</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 6 - Adding Search & Query Capability</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson6/index.html" target="_blank">Assignment 6
              -FeatureLayer.queryFeatures() Demonstration</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson6/index_clean.html" target="_blank">Assignment
              6 -FeatureLayer.definitionExpression Demonstration</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson6/l6_reflect.html" target="_blank">Assignment
              Summary</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 5 - Layer Visualization</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson5/index.html" target="_blank">Assignment
              5</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson5/l5_reflect.html" target="_blank">Assignment
              Summary</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 4 - Adding Layers</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson4/part1/index.html" target="_blank">Part I -
              VectorTileLayer</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson4/part2/index.html" target="_blank">Part II -
              MapImageLayer with sublayer specification</a>
            <br/>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 3 - Introduction to ArcGIS API for Javascript</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson3/index.html" target="_blank">Part I</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson3_2/index.html" target="_blank">Part II with
              Reflection</a>
            <br/>
            <a href="https://codepen.io/atxfarley/pen/Gadrag" target="_blank">CodePen for Part II</a>
            <br/>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 2 - Strict XHTML and CSS</td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/lesson2.html" target="_blank">Lesson 2</a>
            <br/>
            <a href="https://www2.census.gov/library/publications/2013/demo/p70-136.pdf" target="_blank">Original
              source</a>
            <br/>
            Instructions:<br/>
            Do Page 1 & Table 1 on Page 2<br/>
            <a href="https://validator.w3.org/" target="_blank">W3C Markup Validation Service</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 1 Creating Mapping Apps without Programming</td>
          <td colspan="2">
            <strong>Part 1</strong><br/>
            <a href="https://pennstate.maps.arcgis.com/apps/webappviewer/index.html?id=45f360ff4db64635abb0183d320a15d0"
               target="_blank">Link to ESRI Application</a>
            <br/>
            <strong>Part 2</strong><br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/Lesson1.mp4" target="_blank">Link to uMap
              Review</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/Part2.pptx" target="_blank">Supplemental Power
              Point presentation</a>
            <br/>
            <a href="http://umap.fluv.io/en/map/austin-b-cycle-kiosk-locations_6259" target="_blank">Link to uMap
              Demo</a>
            <br/>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 585<br/>Open Web Mapping
            <br/>
            <a href="https://github.com/atxFarley/GEO585" target="_blank" class="headerrowlink">GitHub Repo for GEOG
              585</a>
          </th>
        </tr>
        <tr>
          <td colspan="2">Term Project<br/>
            FOSS Web Mapping Application
          </td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/termProject.mp4" target="_blank">Video</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/termProject.pptx" target="_blank">Supplemental
              PowerPoint</a>
            <br/>
            <a href="https://github.com/atxFarley/GEOG585/tree/master/termProject" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 8 - Independent exploration of FOSS
            <br/>
            D3
          </td>
          <td colspan="2">
            <a href="https://github.com/atxFarley/GEOG585/tree/master/Lesson8/code" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 7 - Make your own mashup with vector layer drawn in the browser
          </td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/texas.html" target="_blank">Web Map</a>
            <br/>
            <a href="https://github.com/atxFarley/GEOG585/tree/master/Lesson7/code" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr>
          <td colspan="2">Lesson 6 - Overlay your own data in Leaflet
          </td>
          <td colspan="2">
            <a href="https://github.com/atxFarley/GEOG585/tree/master/Lesson6/code" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="2">GEOG 481<br/>Topographic Mapping with Lidar</th>
          <th colspan="2">GEOG 586<br/>Geographic Information Analysis</th>
        </tr>
        <tr>
          <td>Term Project<br/>Solar Suitability Analysis Using Lidar</td>
          <td>
            <a href="https://psu.voicethread.com/share/12465944/" target="_blank">Voice Thread</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/final.pptx" target="_blank">Supplemental
              PowerPoint</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/AFarley_Final.pdf" target="_blank">Final Paper</a>
          </td>
          <td>Term Project<br/>Analyzing Agricultural Land Use and Poverty Level Changes by County in Texas from 2008 to
            2017
          </td>
          <td>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/FinalProject_AFarley.pdf" target="_blank">Final
              Paper</a>
            <br/>
            <a href="https://github.com/atxFarley/arcpy_python" target="_blank">GitHub Repo</a>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>
            Lesson 1 - Understand the modifiable areal unit problem and how it is exploited in politics
          </td>
          <td>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/geog586/L1_aFarley.docx" target="_blank">Project 1
              Paper</a>
          </td>
        </tr>
        <tr style="background-color: #D8DAD7">
          <th colspan="4">GEOG 485<br/>GIS Programming and Automation</th>
        </tr>
        <tr>
          <td colspan="2">Term Project<br/>ArcPy, Jpype, Python<br/>
            Project uses ArcPy and jpype module to make RMI calls to Java service <br/>from Python script running inside
            of
            the ArcMap environment. <br/>The purpose was to use the USPS address standardization service (the java app)
            <br/>to
            create an output feature class of standardized addresses based on <br/>non-standardized addresses from an
            input
            file.
          </td>
          <td colspan="2">
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/FinalProject.mp4">Video</a>
            <br/>
            <a href="https://amyfarleysitefiles.s3.amazonaws.com/www/AFarley_FinalProject.docx">Final Paper</a>
            <br/>
            <a href="https://github.com/atxFarley/arcpy_jpype_python" target="_blank">GitHub Repo</a>
          </td>
        </tr>
      </table>
    </div>

  </div>
</div>
